.authenticated header {
    height: 72px;
}

.header-dash-row {
    height: 72px;
    position: relative;
    z-index: 10;
    background-color: #121212;
}

.header-dash-row img {
    height: 40px;
    width: auto;
}

.header-dash-row input {
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 32px;
    padding: 8px 16px;
    width: 100%;
    max-width: 100%;
    height: 40px;
    background-image: url('../../../../public/icon-search-w.svg');
    background-position: center right 16px;
    background-repeat: no-repeat;
    background-size: 24px;
}

.header-dash-row input:focus, 
.header-dash-row input:hover{
    background-color: var(--color-darkgrey);
    color: var(--color-white);
    border-radius: 32px;
}

.header-dash-row .search {
    margin: 0 24px;
    display: flex;
    justify-content: end;
}

.header-dash-row .search form {
    /* max-width: 872px;
    margin: auto; */
    width: 626px;
}

.header-dash-row .faq img {
    height: 24px;
}

.header-dash-row button {
    border: none;
    color: var(--color-white);
    padding: 8px;
    width: 40px;
    height: 40px;
    background: radial-gradient(133.36% 291.04% at 0% 0%, var(--color-gradient-blue) 0%, var(--color-gradient-purple) 100%);
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 32px;
    margin-left: 24px;
}

.header-dash-row button a {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    letter-spacing: var(--spacing-04);
    color: var(--color-white);
    text-decoration: none;
}

.header-dash-row button:hover {
    background: radial-gradient(133.36% 291.04% at 0% 0%, #001B57 0%, #6222BD 100%);
}

.header-dash-row ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-white);
}

.header-dash-row ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-white);
}

.header-dash-row :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-white);
}

.header-dash-row :-moz-placeholder {
    /* Firefox 18- */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-white);
}
