.Toastify__toast-icon {
    display: none !important;
}

.custom-toast.Toastify__toast-container--top-center {
    top: 162px;
}

.custom-toast .Toastify__close-button {
    color: var(--color-black);
    border: 2px solid var(--color-black);
    height: 20px;
    width: 20px;
    border-radius: 10px;
    padding: 1px;
}

.custom-toast .Toastify__close-button svg {
    display: flex;
    align-self: center;
    justify-self: center;
    height: 14px;
    width: 15px;
}

.custom-toast .Toastify__toast-body {
    margin: 0;
    padding: 0;
}

.custom-toast-warning .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #F0E9FA;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 16px;
    width: 424px;
    height: 184px;
    border-left: 4px solid #6C25D0;
    box-shadow: 0px 1px 2px rgba(20, 20, 20, 0.3), 0px 2px 6px 2px rgba(20, 20, 20, 0.15);
    border-radius: 4px;
}

.custom-toast-content {
    font-family: "Figtree", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.toast-text {
    margin: 0 8px;
    width: -webkit-fill-available;
}

.toast-title {
    margin-bottom: 8px;
}

.toast-message {
    font-weight: var(--font-weight-400);
    color: var(--color-black);
}

.toast-link a {
    color: var(--color-black);
}
