a{
font-weight: var(--font-weight-600);
font-size: var(--font-size-16);
line-height: var(--line-height-22);
color: var(--color-darkgrey);
}
form button{
width: 100%;
margin-top: 0;
}
h3{
text-transform: uppercase;
text-align: center;
}
.divider{
margin: 24px 0;
max-width: 424px;
}

.page.register .page-background {
    background-image: url('/public/account/accesso.png');
}
.page.orderconfirmed .page-background {
    background-image: url('/public/order_confimred.jpg');
}
