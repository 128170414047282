
form button{
width: 100%;
margin-top: var(--font-size-16);
}
p{
margin-bottom: 1em;
}

.page.password .page-background{
    background-image: url('/public/account/forgot-password.png');
}

.page.password form{
    padding: 24px 0 0 0;
    width: 424px;
}

.page.password div.text-center{
    padding-top: 24px;
}
