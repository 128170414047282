main.main-landing{
    background-color: #f6f6f6;
}

.banner-landing{
    margin: auto;
    height: 678px;
    width: 100%;
    max-width: unset;
    background-position: top right;
    position: relative;
    background-size: 128%;
    margin-bottom: 120px;
    background-color: rgba(255, 255, 255, 0.08);
}

.banner-overlay{
    background: linear-gradient(286.2deg, rgba(0, 30, 96, 0.08) 0%, #001E60 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.banner-content{
    width: 1440px;
    margin: auto;
}

.banner-headings{
    position: relative;
    z-index: 1;
    padding: 72px 60px 0 60px;
    height: 440px;
}

.banner-headings h1{
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-64);
    line-height: var(--line-height-80);
    color: #E6E9EF;
    margin-bottom: 48px;
    width: 900px;
}

.banner-headings h2{
    font-style: normal;
    font-weight: var(--font-weight-500);
    line-height: 44px;
    font-size: var(--font-size-32);
    line-height: var(--line-height-44);
    color: var(--color-white);
    width: 1086px;
    min-height: 132px;
}

.banner-boxes{
    column-gap: 24px;
    justify-content: left;
    position: relative;
    z-index: 5;
    padding: 0 60px;
}

.banner-boxes>div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;    
    padding: 24px;
    gap: 8px;
    width: 424px;
    height: 325px;
    background: #FDFDFD;    
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 8px;
}

.banner-boxes .box-tag{
    padding: 2px 8px;
    height: 24px;
    background: rgba(108, 37, 208, 0.16);
    border-radius: 16px;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-14);
    color: var(--color-gradient-purple);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-boxes button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 6px;
    height: 40px;
    border-radius: 32px;
    margin: 0;
}

.banner-boxes h3{
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-24);
    line-height: var(--line-height-32);
    color: var(--color-black);
    text-transform: none;
    text-align: left;
    margin-bottom: 8px;
}

.banner-boxes p{
    min-height: 88px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.banner-boxes hr{
    margin-bottom: 8px;
    border: 1px solid #E6E9EF;
}

.content-landing{
    background-color: rgba(255, 255, 255, 0.08);
}

.content-landing h1{
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: 36px;
    line-height: var(--line-height-48);
    color: var(--color-gradient-blue);
    text-transform: inherit;
    padding: 24px;
    margin-bottom: 0;
    text-align: center;
}

.content-landing h4{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    color:  var(--color-darkgrey);
    text-align: center;
    padding-bottom: 24px;
}

.content-landing h5{
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-24);
    line-height: var(--line-height-30);
    color: var(--color-black);
    margin-bottom: 8px;
}

.landing-cards{
    column-gap: 24px; 
    width: 1320px;
    margin: auto;
    margin: 32px auto;
}

.landing-cards>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 312px;
    height: 411px;
    background: #FDFDFD;
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 12px;
    position: relative;
}

.landing-cards img{
    width: 296px;
    height: 395px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    z-index: 0;
    border-radius: 8px;
}

.landing-cards .chip{
    left: 16px;
    width: 71px;
}

.landing-cards .pricetag{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 12px;
    position: absolute;
    min-width: 108px;
    height: 48px;
    right: 0px;
    bottom: 20px;
    background: var(--color-lime);
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 8px 0px 0px 8px;
}

.landing-cards .pricetag span{
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-24);
    line-height: var(--line-height-32);
    color: var(--color-gradient-blue);
}

.divider-landing{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 24px 0;
    width: 872px;
    margin: auto;
}

.divider-landing hr{
    width: 290px;
    border-top: 1px solid #9BABE1;
}

.divider-landing div{
    margin: 0 16px;
}

.divider-landing a{
    text-decoration: none;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-gradient-blue);
    text-transform: uppercase;
}

.landing-news{
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    margin: 40px auto 0 auto;
    width: 1320px;
    padding-bottom: 56px;
}

.landing-news>div{
    height: 405px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    background-color: var(--color-white);
    border-radius: 4px;
}

.landing-news .news-image{
    height: 212px;
    width: 100%;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    position: relative;
}

.landing-news .news-image img{
    height: auto;
    width: 100%;
    object-fit: cover; 
}

.landing-news .card-content, 
.collaborate-landing .card-content{
    padding: 16px;
}

.landing-news .card-content p, 
.collaborate-landing .card-content p{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);  
    line-height: var(--line-height-22);
    color: var(--color-darkgrey);
    padding-bottom: 8px;
    margin-bottom: 12px;
    height: 94px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.landing-news .card-content hr, 
.collaborate-landing .card-content hr{
    border: none;
    border-top: 1px solid #E6E9EF;
    width: 100%;
}

.landing-news .card-content a, 
.collaborate-landing .card-content a{
    display: block;
    margin-top: 12px;
    width: auto;
    color: var(--color-gradient-blue);
    background-image: url('/public/icon-arrow-blue.svg');
    background-repeat: no-repeat;
    background-position: center right;
    text-decoration: none;
}

.landing-news .card-content a:hover{
    text-decoration-line: underline;
    color: #001E60;
}

.landing-news.grid-2>div{
    min-width: 648px;
    max-width: 648px;
}

.landing-news.grid-3>div{
    min-width: 424px;
    max-width: 424px;
}

.landing-news.grid-4>div{
    min-width: 312px;
    max-width: 312px;
}

.activate-landing{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0px;
    gap: 40px;
    width: 100%;
    height: 414px;
    background: rgba(0, 30, 96, 0.12);
}

.activate-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 24px;
    gap: 24px;
    width: 1144px;
    height: 302px;
}

.activate-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 536px;
    height: 302px;
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 8px;
}

.activate-left img{
    width: 536px;
    height: 302px;
    border-radius: 8px;
    object-fit: cover;
}

.activate-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 560px;
    height: 302px;
}

.activate-right h1{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-32);
    line-height: var(--line-height-44);
    color: #2E2E2E;
}


.activate-right h2{
    font-weight: var(--font-weight-600);
    font-size: 20px;
    line-height: var(--line-height-28);
    color: var(--color-darkgrey);
}

.activate-right p{
    font-weight: var(--font-weight-600);
    color: var(--color-medium-grey);
}

.activate-right hr{
    width: 560px;
    height: 1px;
    border: none;
    border-top: 1px solid #E6E9EF;
}

.activate-right button{
    width: 245px;
    justify-content: space-between;
}

.collaborate-landing{
    flex-direction: column;
    align-items: center;
    padding: 40px 0px 72px;
    gap: 24px;
    height: 403px;
}

.collaborate-cards{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 1096px;
    height: 171px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.collaborate-cards.grid-2 .card-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 536px;
    height: 171px;
    background: var(--color-white);
    border-radius: 4px;
}

.collaborate-cards.grid-4{
    width: 1320px;
}

.collaborate-cards.grid-4 .card-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 312px;
    height: 215px;
    background: var(--color-white);
    border-radius: 4px;
}

.collaborate-cards.grid-2 .card-info p{
    height: 52px;
    text-overflow: unset;
}

.collaborate-cards.grid-4 .card-info p{
    height: 98px;
    text-overflow: unset;
}

.collaborate-cards a:hover{
    text-decoration: underline;
}

/* Landing EDU */

.landing-edu{
    background-image: url(/public/landing/background-edu.png);
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px 64px;
    gap: 56px;
    /*width: 1440px;*/
    max-width: unset;
}

.landing-edu .edu-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 1201px;
    /*height: 390px;*/
    height: 214px;
}

.landing-edu .top-headings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    gap: 32px;
    width: 1201px;
    height: 214px;
}

.landing-edu .top-headings h1{
    margin: 0;
    width: 1169px;
    height: 96px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #FDFDFD;
}

.landing-edu .top-headings h2{
    margin: 0;
    width: 1169px;
    height: 38px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #FDFDFD;
}

.landing-edu .top-headings h2 span{
    text-decoration-line: line-through;
}

.landing-edu .edu-countdown{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 40px;
    width: 568px;
    height: 144px;
}

.landing-edu .edu-cards{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;
    width: 1096px;
    height: 610px;
}

.landing-edu .edu-cards .card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 16px;
    width: 524px;
    height: 610px;
    background: #FDFDFD;
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 8px;
}

.landing-edu .edu-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 500px;
    height: 84px;
}

.landing-edu .edu-info p{
    margin: 0;
    width: 500px;
    height: 84px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #FDFDFD;
}

.landing-edu .landing-cards .card .card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 8px;
    gap: 24px;
    width: 460px;
    height: 562px;
}

.landing-edu .landing-cards .card .card-content .content-top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 460px;
    height: 214px;
}

.card .content-top .top-info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 8px;
    width: 460px;
    height: 30px;
}

.card .content-top .top-info .info-chip{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 12px;
    width: 123px;
    height: 30px;
    background: #D4FF5B;
    border-radius: 16px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}

.card .content-top .bottom-info{
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 460px;
    height: 184px;
}

.card .bottom-info .promo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 460px;
    height: 86px;
    align-items: baseline;
}

.card .bottom-info .promo .promo-headings{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
}

.card .bottom-info .promo-headings h2{
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #252525;
}

.card .bottom-info .promo-headings span{
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #252525;
}

.card .bottom-info .promo-chip{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 12px;
    height: 30px;
    background: rgba(108, 37, 208, 0.16);
    border-radius: 16px;
}

.card .bottom-info .promo-chip span{
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #6C25D0;
    text-transform: uppercase;
}

.card .bottom-info .pricing{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    width: 460px;
    height: 74px;
}

.card .bottom-info .pricing h2{
    width: 198px;
    height: 44px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    background: linear-gradient(98.36deg, #001E60 -0.32%, #6C25D0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.card .bottom-info .pricing h3{
    margin: 0;
    width: 460px;
    height: 24px;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #363636;
    text-transform: none;
    text-align: inherit;
}

.card .bottom-info .action{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px 8px;
    width: 460px;
    height: 82px;
}

.card .bottom-info .action a{
    gap: 8px;
    width: 460px;
    height: 50px;
}

.landing-edu .button-disabled, 
.landing-edu .button-disabled:hover{
    background: #F1F1F1;
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    color: #525252;
}

.card.card.disabled-card{
    position: relative;
}

.card.disabled-card .overlay{
    background: #FDFDFD;
    opacity: 0.2;
    border-radius: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.landing-edu .edu-faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    width: 920px;
    background: #FDFDFD;
    border-radius: 8px;
}

.landing-edu .edu-faq>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 80px;
    width: 872px;
}

.landing-edu .edu-faq h1{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: 0 auto;
    width: 200px;
    height: 44px;
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #001E60;
}

.landing-edu .edu-faq .accordion-button:not(.collapsed){
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    height: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2E2E2E;
}
