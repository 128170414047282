footer {
    background-color: #000D28;
}

footer .footer-wrapper{
    max-width: 1440px;
    margin: auto;
    padding: 0 60px 24px 60px;
}

footer.black-footer{
    background-color: var(--color-black-900);
}

footer a,
footer p,
footer span {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-14);
    line-height: var(--line-height-20);
    color: #F6F6F6;
    text-decoration: none;
    display: block;
    height: 24px;
    padding: 2px;
}

footer p {
    margin-bottom: 0;
}

footer span {
    font-size: 11px;
    line-height: var(--line-height-14);
    color: #F1F1F1;
}

footer h1 {
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-24);
    line-height: var(--line-height-30);
    color: #9BABE1;
}

footer.black-footer h1{
    color: var(--color-lime);
}

footer hr {
    border-top: 1px solid #334B80;
    margin: 8px 0;
}

footer .row:nth-of-type(1) {
    padding: 24px 0;
}

footer .logo {
    width: 200px;
}

footer .social {
    margin-top: 40px;
}

footer .social a {
    margin-right: 12px;
    display: inline;
}

footer .social img {
    max-height: 24px;
    margin: 14px;
}

footer .terms {
    display: flex;
    justify-content: end;
}

footer .terms a {
    display: inline;
    margin: 0 8px;
}
