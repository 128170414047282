.codeunlock .headings{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    max-width: 536px;
}

.codeunlock .contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    max-width: 536px;
}

.codeunlock hr{
    width: 536px;
    border: none;
    border-top: 1px solid #E6E9EF;
    margin: 24px 0;
}

.codeunlock h1{
    margin: 0;
}

.codeunlock h2{
    margin: 0;
    font-weight: var(--font-weight-600);
    font-size: 22px;
    line-height: var(--line-height-30);    
    color: var(--color-darkgrey);
}

.codeunlock .bottom-info .list{
    width: auto;
}
