
form button{
width: 100%;
margin-top: var(--font-size-16);
}
p{
margin-bottom: 1em;
}
form button a{
font-weight: var(--font-weight-700);
font-size: var(--font-size-18);
line-height: var(--line-height-24);
letter-spacing: var(--spacing-04);
color: var(--color-white);
text-decoration: none; 
}

.page.updated .page-background{
    background-image: url('/public/account/forgot-password.png');
}
