/* altezza header cambiata da 114px a 72px per sistemare la grafica dopo aver tolto header-row-top su richiesta del 22 ottobre 2024  */

header {
    /*height: 114px;*/
    height: 72px;
}
header.headermini{
    height: 72px;
}

.header-wrapper{
    width: 100vw;
}

.header-wrapper .row {
    max-width: 1440px;
    margin: auto;
    padding: 0 60px;
}

/* header-row-top nascosto in tutte le pagine su richiesta del 22 ottobre 2024 durante call di review responsive */

.header-row-top {
    background-color: var(--color-blue);
    height: 42px;
    display: none;
}

.header-row-top a {
    color: var(--color-white);
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px;
    font-weight: var(--font-weight-500);
    padding-right: 16px;
}

.header-row-top button {
    background-color: var(--color-lime-opacity);
    border: none;
    border-radius: 16px;
    padding: 2px 12px;
}

.header-row-top button span {
    color: var(--color-lime);
    font-weight: var(--font-weight-700);
    line-height: var(--line-height-16);
    font-size: var(--font-size-14);
    letter-spacing: var(--spacing-02);
}

.header-row-bottom {
    height: 72px;
    background: var(--color-lightgrey);
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    position: relative;
    z-index: 10;
}

.header-row-bottom img {
    height: 40px;
    width: auto;
}

.header-row-bottom input {
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
    border-radius: 32px;
    padding: 8px 16px;
    width: 100%;
    max-width: 100%;
    background-image: url('../../../../public/icon-search.svg');
    background-position: center right 16px;
    background-repeat: no-repeat;
    background-size: 24px;
}

.header-row-bottom input:focus {
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 32px;
}

.header-row-bottom input:active{
    border-radius: 32px;
}

.header-row-bottom .search {
    margin: 0 24px;
    display: flex;
    justify-content: end;
}

.header-row-bottom .search form {
    /*max-width: 872px;*/
    width: 626px;
}

.header-row-bottom .search form input:hover{
    border-radius: 32px;
}

.header-row-bottom .faq img {
    height: 24px;
}

.header-row-bottom button {
    border: none;
    color: var(--color-white);
    padding: 8px 16px;
    width: 96px;
    height: 40px;
    background: radial-gradient(133.36% 291.04% at 0% 0%, var(--color-gradient-blue) 0%, var(--color-gradient-purple) 100%);
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 32px;
    margin-left: 24px;
}

.header-row-bottom button.active-user {
    width: 40px;
    padding: 0;
}

.header-row-bottom button a {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    letter-spacing: var(--spacing-04);
    color: var(--color-white);
    text-decoration: none;
}

header ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: #8A98B6;
}

header ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: #8A98B6;
}

header :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: #8A98B6;
}

header :-moz-placeholder {
    /* Firefox 18- */
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: #8A98B6;
}

header .open-menu {
    position: relative;
}

#collapse-menu {
    position: absolute;
    width: 200px;
    height: 239px;
    right: 0;
    top: 48px;
    z-index: 90;
}

.menu-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8px;
    gap: 4px;
    background: #FDFDFD;
    border: 1px solid rgba(0, 30, 96, 0.16);
    box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
    border-radius: 8px;
}

.black-menu .menu-container{
    background-color: #1C1C1C;
    border: 1px solid rgba(0, 30, 96, 0.16);
}

.menu-container>div {
    width: 100%;
}

.menu-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #9BABE1;
}

.black-menu .menu-container ul{
    border-bottom: 1px solid rgba(212, 255, 91, 0.16);
}

.menu-container>div:last-of-type ul {
    border-bottom: none;
}

.menu-container ul li {
    height: 27px;
    margin: 2px 0;
    display: flex;
    justify-content: end;
    align-items: center;
}

.menu-container ul li:hover {
    background: #F2F3F6;
    border-radius: 2px;
}

.menu-container ul li.selected {
    background: #E6E9EF;
    border-radius: 2px;
}

.menu-container ul li a,
.menu-container ul li span {
    text-decoration: none;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-black);
    margin: 0 8px;
    cursor: pointer;
}

.black-menu .menu-container ul li a,
.black-menu .menu-container ul li span{
    color: #FDFDFD;
}

.menu-container ul li:hover a,
.menu-container ul li:hover span,
.menu-container ul li.selected a {
    background: linear-gradient(98.36deg, #001E60 -0.32%, #6C25D0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.black-menu .menu-container ul li:hover{
    background: rgba(212, 255, 91, 0.08);
}

.black-menu .menu-container ul li.selected{
    background: rgba(212, 255, 91, 0.16);
}

.black-menu .menu-container ul li:hover a, 
.black-menu .menu-container ul li:hover span, 
.black-menu .menu-container ul li.selected a {
    color: var(--color-lime);
    -webkit-text-fill-color: initial;
}

.search-mobile, 
.open-menu-mobile, 
.menu-mobile {
    display: none;
}
