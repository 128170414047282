.account-container .account-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 72px;
    background: rgba(0, 30, 96, 0.08);
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.account-container .account-top .row {
    width: 1320px;
}

.account-container .account-top h1 {
    font-weight: var(--font-weight-600);
    font-size: 22px;
    line-height: var(--line-heigth-30);
    color: var(--color-black);
    margin: 0 auto;
}

.account-top .prev-page a{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px 8px 8px;
    gap: 6px;
    width: 125px;
    height: 40px;
    background: #FDFDFD;
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 24px;
    text-decoration: none;
}

.account-top .prev-page span{
    font-weight: var(--font-weight-500);   
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    color: #000000;
}

.account-container .account-body {
    max-width: 872px;
    margin: auto;
    padding: 24px 0;
}

.account-container .account-body>div {
    padding: 16px 0;
}

.account-container .account-body h2 {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-24);
    line-height: var(--line-heigth-32);
    color: var(--color-black);
    margin-bottom: 16px;
}

.account-container .account-body h3 {
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: var(--font-weight-500);
    font-size: 22px;
    line-height: var(--line-heigth-30);
    color: var(--color-black);
    text-align: left;
    text-transform: none;
}

.account-container .account-body .checkbox-description{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    color: var(--color-darkgrey);
}

.account-container .account-body a.checkbox-description{
    font-weight: var(--font-weight-600);
}

.account-container .account-body .row {
    max-width: 648px;
    margin: 0 auto 16px auto;
}

.account-container form {
    padding: 16px 0;
}

.account-container .buttons-container {
    padding: 48px 0 16px 0;
}

.account-container ul {
    margin-bottom: 16px;
}

.account-body img {
    margin-right: 8px;
}

.account-body row.customer-care{
    padding-top: 0;
}

.account-body .customer-care .col-6{
    padding: 16px;
}

.account-body .customer-care .col-6:first-of-type{
    padding-right: 28px;
}
.account-body .customer-care .col-6:last-of-type{
    padding-left: 28px;
}

.account-body .customer-care h3{
    line-height: var(--line-heigth-32);
}

.account-body .customer-care a {
    text-decoration: none;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: #546894;
}

.account-body .customer-care hr {
    border-top: 1px solid #E6E9EF;
    width: 100%;
    margin-bottom: 12px;
}

.account-body p span {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-darkgrey);
    cursor: pointer;
    text-decoration: underline;
}
