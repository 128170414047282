.accordion{
    width: 648px;
    margin: auto;
}
.accordion-item{
    border: none;
    border-bottom: 1px solid #E6E9EF;
    height: auto;
    margin-bottom: 32px;
    gap: 24px;
}
.accordion-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #2E2E2E;
    margin-bottom: 24px !important;
}
.accordion-header button{
    font-weight: var(--font-weight-600);
    font-size: 18px;
    line-height: var(--line-height-18);
    box-shadow: none;
    border-radius: none;
    margin: 0 !important;
    padding: 0;
}

.accordion-button.collapsed{
    border-bottom: none;
}

.accordion-button:not(.collapsed){
    background-color: var(--color-white);
    box-shadow: none;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed, 
.accordion-item:first-of-type>.accordion-header .accordion-button{
    border-radius: 0;
}

.accordion-body p{
    max-width: 90%;
}
