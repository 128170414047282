.tabs-container, 
.purchase-history{
    width: 760px;
    margin-left: auto;
}

.account-container .account-body>div.tabs-container{
    padding-bottom: 104px;
}

.account-container ul{
    margin: 0;
}

.product-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 16px;
    width: 760px;
    height: 222px;
    background: #FDFDFD;
    border: 1px solid rgba(0, 30, 96, 0.08);
    border-radius: 4px;
    margin-bottom: 8px;
}

.account-body .product-container .row, 
.account-body .purchase-history .row{
    margin: 0;
    max-width: 100%;
}

.product-container .row-top>div{
    display: flex;
    flex-direction: row;
}

.account-body .product-container h3, 
.account-body .purchase-history h3{
    font-weight: var(--font-weight-700);
    text-transform: capitalize;
}

.product-container h4{
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-16);  
    line-height: var(--line-height-22);
    color: var(--color-black);
    margin-bottom: 0;
    margin-right: 16px;
}

.purchase-history h5{
    font-weight: var(--font-weight-700);
    column-rule: var(--color-medium-grey);
}

.product-container .state{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 6px;
    gap: 4px;
    height: 20px;
    border-radius: 2px;
}

.product-container .state span{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);    
}

.product-container .state.active{
    background: #E3F0E8;
    border: 1px solid #15803D;
}

.product-container .state.active span{
    color: #15803D;
}

.product-container .state.inactive{
    background: #F7E4E4;
    border: 1px solid #B91C1C;
}

.product-container .state.inactive span{
    color: #B91C1C;
}

.product-container .state.returned{
    background: #FCEBE2;
    border: 1px solid #EA580C;
}

.product-container .state.returned span{
    color: #EA580C;
}

.product-container img{
    margin: 0;
    max-width: 120px;
}

.product-container img.cover{
    border-radius: 4px;
    margin-right: 16px;
    color: var(--color-medium-grey);
}

.product-container p{
    margin-bottom: 8px;
    font-weight: var(--font-weight-400);
}

.product-container p span, 
.purchase-history p span{
    text-decoration: none;
    font-weight: var(--font-weight-600);
}

.product-container button{
    margin-bottom: 12px;
}

.product-container .gradient-button{
    padding: 8px 16px;
    gap: 4px;
    width: 184px;
    height: 36px;
    border-radius: 32px;
    font-size: var(--font-size-16);
}

.product-container .secondary-button, 
.product-container .reset-button{
    background: #F2F3F6 !important;
    border-radius: 32px;
}

.product-container .secondary-button span, 
.product-container .reset-button span{
    background: radial-gradient(133.36% 291.04% at 0% 0%, #001E60 0%, #6C25D0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.purchase-history{
    margin-bottom: 48px;
}

.history-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    isolation: isolate;
    width: 760px;
    height: 138px;
    background: #F1F1F1;
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 8px;
    margin-bottom: 16px;
}

.purchase-history span{
    font-size: var(--font-size-14);
    line-height: var(--line-height-20);
    color: var(--color-medium-grey);
    margin-right: 8px;
}

.purchase-history .download{
    justify-content:space-between;
    align-items: end;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
}

.purchase-history .download img{
    height: 44px;
}

.purchase-history .download p span{
    margin: 0;
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-18);  
    line-height: var(--line-height-24);
    color: var(--color-black);
}

.tabs-container>.reset-button, 
.purchase-history>.reset-button{
    width: 356px;
    height: 40px;
    background: #F2F3F6 !important;
    border-radius: 8px;
    margin: 32px auto 0 auto;
}

.purchase-history>.reset-button{
    margin: 48px auto 0 auto;
}

.tabs-container>.reset-button span,
.purchase-history>.reset-button span{
    letter-spacing: 0.4px;
    background: radial-gradient(133.36% 291.04% at 0% 0%, #001E60 0%, #6C25D0 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: var(--font-size-18);
}
.noproducts h3{
	text-align:center !important;
	font-weight: var(--font-weight-700);
}
