body {
  font-family: "Figtree", sans-serif;
  font-size: 1rem;
  color: var(--color-black-900);
  margin: 0;
}

/* variabili colore generiche */
:root {
  --color-primary: #656565;
  --color-blue: #000D28;
  --color-darkgrey: #363636;
  --color-medium-grey: #525252;
  --color-lightgrey: #fdfdfd;
  --color-gradient-blue: #001e60;
  --color-gradient-purple: #6C25D0;
  --color-gradient-purple-bg: #F0E9FA;
  --color-background: rgb(0 30 96 / 8%);
  --color-border: rgb(0 30 96 / 15%);
  --color-white: #fff;
  --color-black: #252525;
  --color-lime: #d4ff5b;
  --color-lime-opacity: rgb(212 255 91 / 8%);
  --color-alert: #B91C1C;
  --color-black-900: #121212;
}

/* font variabili grandezze pesi e altezze */
:root {
  --spacing-02: 0.2px;
  --spacing-04: 0.4px;
  --spacing-05: 0.5px;
}

:root {
  /* Font Sizes */
  --font-size-10: 0.625em; /* 10px */
  --font-size-11: 0.6875em; /* 11px */
  --font-size-12: 0.75em; /* 12px */
  --font-size-13: 0.8125em; /* 13px */
  --font-size-14: 0.875em; /* 14px */
  --font-size-15: 0.9375em; /* 15px */
  --font-size-16: 1em; /* 16px */
  --font-size-18: 1.125em; /* 18px */
  --font-size-20: 1.25em; /* 20px */
  --font-size-24: 1.5em; /* 24px */
  --font-size-28: 1.75em; /* 28px */
  --font-size-32: 2em; /* 32px */
  --font-size-36: 2.25em; /* 36px */
  --font-size-48: 3em; /* 48px */
  --font-size-60: 3.75em; /* 60px */
  --font-size-64: 4em; /* 64px */
  --font-size-72: 4.5em; /* 72px */

  /* Line Heights */
  --line-height-80: 5rem;
  --line-height-48: 3rem;
  --line-height-44: 2.75rem;
  --line-height-38: 2.38rem;
  --line-height-33: 2.063rem;
  --line-height-32: 2rem;
  --line-height-30: 1.90rem;
  --line-height-28: 1.75rem;
  --line-height-26: 1, 60rem;
  --line-height-24: 1.50rem;
  --line-height-22: 1.375rem;
  --line-height-20: 1.25rem;
  --line-height-19: 1.2rem;
  --line-height-16: 1rem;

  /* Font Weights */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
}


/* variabili colori EDU */
:root{
  --dark-green: #3D9985;
  --light-green: #93C462;
  --marine: #63C3D1;
  --blue: #6F9ED4;
  --magenta: #CA69A6;
  --purple: #9C8FC4;
}

.App{
  background-color: #fdfdfd;
}

.App.landing{
  background-color: #F6F6F6;
}

header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}

main{
  padding-top: 72px;
  scrollbar-width: none;
}

main>div:first-of-type:not(.divider-landing){
  min-height: 50vh;
}

main>section{
  max-width: 1440px;
  margin: auto;
}

.page{
  width: 1440px;
  margin: auto;
}

.page.register, 
.page.login, 
.page.password,
.page.updated, 
.page.firstaccess{
  width: 100%;
}

.page h1 {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-36);
  line-height: var(--line-height-48);
  background: -webkit-linear-gradient(98.36deg, var(--color-gradient-blue) -0.32%, var(--color-gradient-purple) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

h2 {
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-18);
  line-height: var(--line-height-28);
  color: var(--color-black);
  margin: 0;
}

h3 {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-gradient-blue);
}

h4 {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-darkgrey);
}

h5 {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  color: var(--color-darkgrey);
}

ul {
  color: var(--color-darkgrey);
}

input:not([type='checkbox']):hover {
  border: 1px solid var(--color-black);
  box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
  outline: none;
  border-radius: 4px;
}

input[type="radio"]:hover, 
input[type="radio"]:focus{
  box-shadow: none;
}

/*
input:not([type='checkbox']):valid{
background: var(--color-lightgrey);
border: 1px solid var(--color-gradient-blue);
}
*/

input:not([type='checkbox']).invalid {
  background-color: var(--color-lightgrey);
  border: 1px solid var(--color-alert);
}

input:not([type='checkbox']):focus {
  background-color: var(--color-lightgrey);
  box-shadow: 0px 2px 6px 2px rgba(0, 30, 96, 0.15);
  border: 1px solid var(--color-gradient-purple);
  border-radius: 4px;
}

input:not([type='checkbox']),
select {
  padding: 12px 8px;
  height: 44px;
  background: var(--color-lightgrey);
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  width: 100%;
  max-width: 424px;
  outline: none;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  color: var(--color-black);
}

select {
  padding: 12px 34px 12px 8px;
}

input:not([type='checkbox']):read-only{
  background: #F1F1F1;
  border: 1px solid #001E60;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"]{
  box-shadow: none;
  height: 18px;
  width: 18px;
  accent-color: var(--color-black);
  flex-shrink: 0;
  margin-top: 4px;
}

input[type="checkbox"].invalid{
  accent-color: var(--color-alert);
  border-color: var(--color-alert);
}

.input-pwd{
  position: relative;
}

.input-pwd img{
  position: absolute;
  right: 22px;
  top: 40px;
}

.form-check.form-switch{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 177px;
  height: 40px;
  background: rgba(108, 37, 208, 0.12);
  border-radius: 20px;
  padding: 8px 16px 8px 12px;
  margin-bottom: 24px;
}
.form-check.form-switch input{
  width: 20px;
  height: 14px;
  float: none;
  margin: 0;
  -webkit-appearance: none;
  border-color: var(--color-gradient-purple);
  accent-color: var(--color-gradient-purple);
}
.form-check.form-switch label{
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-gradient-purple);
}

.form-check-input:checked{
  background-color: var(--color-gradient-purple);
}

form span, 
.checkout-body .form-checkout span {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  color: var(--color-medium-grey);
}

form .error-message{
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  color: var(--color-black);
  padding: 4px 8px;
  position:absolute;
}

.custom-select-container {
  position: relative;
  display: inline-block;
  min-width: 424px;
}

.custom-select-container select {
  font-size: var(--font-size-16);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../public/select.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: center;
  line-height: var(--line-height-22);
}

.custom-select-container select:focus {
  outline: none;
  border-color: #777;
}

select option {
  padding: 8px;
}

label {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  color: var(--color-medium-grey);
}

p {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-darkgrey);
  margin: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  color: var(--color-darkgrey);
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  color: var(--color-darkgrey);
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  color: var(--color-darkgrey);
}

:-moz-placeholder {
  /* Firefox 18- */
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  color: var(--color-darkgrey);
}

hr {
  border: inherit;
  border-bottom: 1px solid #E6E9EF;
  width: 100%;
  margin: 0;
}

.spacer {
  height: 24px;
}

form>div, 
.form-checkout>div {
 /* margin-bottom: 16px; */
 margin-bottom: 24px;
}

form>div ul {
  margin: 0;
}

form>div ul li {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-darkgrey);
}

button.gradient-button,
a.gradient-button,
input:not([type='checkbox']).gradient-button {
  padding: 12px 16px;
  height: 50px;
  background: radial-gradient(133.36% 291.04% at 0% 0%, var(--color-gradient-blue) 0%, var(--color-gradient-purple) 100%);
  box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
  border-radius: 8px;
  border: none;
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-18);
  letter-spacing: var(--spacing-05);
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.gradient-button:hover,
a.gradient-button:hover,
input:not([type='checkbox']).gradient-button:hover {
  background: radial-gradient(133.36% 291.04% at 0% 0%, #001B57 0%, #6222BD 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

button.gradient-button.reset-button,
a.gradient-button.reset-button,
input.gradient-button.reset-button {
  background: #F7F3FC;
  border: 2px solid var(--color-gradient-purple);
  color: var(--color-gradient-purple);
}

button.gradient-button.reset-button:hover,
a.gradient-button.reset-button:hover,
input.gradient-button.reset-button:hover {
  background: #F0E9FA;
}

button.purple-button,
a.purple-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 2px;
  width: 220px;
  height: 32px;
  background: var(--color-gradient-purple);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-14);
  line-height: var(--line-height-16);
  letter-spacing: 0.2px;
  color: var(--color-white);
  border: none;
  margin: 12px auto 0 auto;
  text-decoration: none;
}

button.purple-button:hover,
a.purple-button:hover {
  background: #6222BD;
}

button.border-button,
a.border-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 2px;
  width: 220px;
  height: 32px;
  background: #F7F3FC;
  border: 2px solid var(--color-gradient-purple);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-14);
  line-height: var(--line-height-16);
  letter-spacing: 0.2px;
  color: var(--color-gradient-purple);
  margin: 12px auto 0 auto;
  text-decoration: none;
}

button.border-button:hover,
a.border-button:hover {
  background: #F0E9FA;
}

.space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page {
/*  height: calc(100vh - 114px); */
  height: calc(100vh - 72px);
}
.page.register, .page.password{
  height: calc(100vh - 72px);
}

.page-background {
  background-image: url('/public/placeholder.png');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}

.page-right {
  padding: 40px 60px 40px 112px;
  overflow-y: auto;
  max-height: calc(100vh - 76px);
  scrollbar-width: none;
}

.page-right form {
  padding-right: 124px;
  max-width: 548px;
}

.page-right form p span{
  font-size: var(--font-size-16);
  text-decoration: underline;
  font-weight: var(--font-weight-600);
  color: var(--color-black);
}

.google-access {
  width: 424px;
  height: 55px;
  box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
  align-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  background: var(--color-white);
  border: 1px solid #E0E0E9;
  border-radius: 4px;
}

.google-access span {
  font-family: 'Lato';
  font-weight: var(--font-weight-500);
  font-size: 20px;
  color: #1D1C2B;
  margin-left: 16px;
}

.google-access a {
  text-decoration: none;
  display: flex;
}

.login-error{
  gap: 8px;
  height: 38px;
  display: flex;
  background: #F7E4E4;
  border-radius: 12px;
  padding: 8px 0px;
  justify-content: center;
}

.login-error span{
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-alert);
}

.chip {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 4px;
  height: 36px;
  background: var(--color-white);
  border: 1px solid var(--color-gradient-purple);
  border-radius: 24px;
  margin: 0 12px 12px 0;
  cursor: pointer;
}

.chip:hover {
  background-color: var(--color-gradient-purple-bg);
}

.chip:has(.chip-checked) {
  background-color: var(--color-gradient-purple);
}

.chip p {
  margin: 0;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  color: var(--color-gradient-purple);
}

.chip:has(.chip-checked) p {
  color: var(--color-white);
}

.chip-icon {
  background-image: url('/public/check.svg');
  height: 20px;
  width: 20px;
}

.chip-checked {
  background-image: url('/public/checked.svg');
  height: 20px;
  width: 20px;
}

.custom-carousel-container .chip, 
.discover-box .chip, 
.dash-box.continue-box .img-cover .chip, 
.landing-cards .chip{
    background-color: var(--color-lime);
    border: none;
    color: var(--color-gradient-blue);
    padding: 4px 8px 4px 6px;
    width: fit-content;
    min-width: 24px;
    height: 24px;
    box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
    border-radius: 16px;
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);
    background-image: url('/public/icon-check.svg');
    background-position: center left 6px;
    background-repeat: no-repeat;
    justify-content: right;
    position: absolute;
    top: 16px;
    right: 0;
}

.tags {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
}

.tag {
  padding: 8px 16px;
  height: 40px;
  min-width: 40px;
  background: var(--color-lime-opacity);
  border: 1px solid var(--color-lime);
  border-radius: 20px;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-lime);
  margin-right: 24px;
}

.tag span {
  padding-left: 24px;
}

.tag.addons {
  background-image: url('/public/icon-addons.svg');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center left 12px;
}

.tag.audio {
  background-image: url('/public/icon-audio.svg');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center left 7px;
}

.tag.bookmark {
  background-image: url('/public/icon-bookmark.svg');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center left 7px;
}

.tag.goto {
  background-image: url('/public/icon-arrow.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center right 7px;
  width: auto;
  height: 34px;
  border-radius: 16px;
  padding: 5px 12px;
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.tag.goto:hover{
  background-color: rgba(212, 255, 91, 0.16);
  border-radius: 16px;
}

.tag.goto span {
  padding-left: 0;
  padding-right: 20px;
}

button.yellow-button,
a.yellow-button {
  padding: 0 12px;
  min-width: 88px;
  height: 34px;
  background: rgba(212, 255, 91, 0.08);
  border: 1px solid rgba(212, 255, 91, 0.12);
  border-radius: 4px;
  margin-right: 12px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

button.yellow-button:hover,
a.yellow-button:hover{
  background-color: rgba(212, 255, 91, 0.12);
  border: 1px solid rgba(212, 255, 91, 0.16);
}

button.yellow-button.selected, 
a.yellow-button.selected{
  background: rgba(212, 255, 91, 0.16);
  border: 1px solid #D4FF5B;
}

button.yellow-button span {
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: #C1E853;
  opacity: 0.8;
}

button.yellow-button.selected span,
a.yellow-button span {
  color: var(--color-lime);
}

button.yellow-button.goto,
a.yellow-button.goto {
  background-image: url('/public/icon-arrow.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center right 7px;
  padding-right: 24px;
  width: 118px;
}

.bottom-info .list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 460px;
  height: 210px;
}

.bottom-info .list h4{
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #363636;
  margin: 0;
}

.bottom-info .list .list-items{
  display: flex;  
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.bottom-info .list .list-items>div{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
}

.bottom-info .list .list-items>div span{
  font-family: 'Figtree';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #363636;
  font-weight: 500;

}

.bottom-info .list .list-items>div span.bold{
  font-weight: 700;
}

/* modali */

.modal{
  --bs-modal-header-padding-y: 12px;
  --bs-modal-header-padding-x: 12px;
}

.modal-header {
  padding: 0;
  margin: 12px 12px 0 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #9BABE1;
}

.modal-body {
  padding: 0;
  margin: 12px 12px 0 12px;
  padding: 0 8px 12px 8px;
  border-bottom: 1px solid #9BABE1;
}

.modal-body h4 {
  font-weight: var(--font-weight-700);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-black);
}

.modal-body p{
  color: var(--color-black);
  margin-bottom: 12px;
}

.modal-header .modal-title{
  display: flex;
}

.modal-header h2 {
  font-size: inherit;
  font-weight: var(--font-weight-700);
  font-size: 18px;
  line-height: var(--line-heigth-24);
  color: var(--color-gradient-blue);
}

.modal-header .btn-close{
  --bs-btn-close-bg: url("/public/close-x.svg");
  width: 24px;
  height: 24px;
  padding: 0;
  -bs-btn-close-opacity: 1 !important;
  --bs-btn-close-hover-opacity: 1;
  --bs-btn-close-opacity: 1;
  --bs-btn-close-focus-shadow: none;
  background-size: auto;
  margin: calc(-.5* var(--bs-modal-header-padding-y)) 0 calc(-.5* var(--bs-modal-header-padding-y)) auto;
}

.modal-footer {
  padding: 0;
  border: none;
  padding-top: 16px;
  margin: 0 12px 16px 12px;
  justify-content: space-between;
}

.modal-footer button {
  width: 47%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  background: var(--color-gradient-blue);
}

.modal-footer button:hover{
  background: #001B57;
}

.modal-footer button.reset-button{
  background: #F2F3F6;
  border: 2px solid #001E60;
  color: var(--color-gradient-blue);
  -webkit-text-fill-color: initial;
}

.modal-footer button.reset-button:hover{
  background: #E6E9EF;
}

.modal-footer .modal-close-button {
  width: 100%;
}

.modal-body input {
  max-width: 100%;
  margin-bottom: 12px;
}

.modal-body .find-code-container p{
  display: block;
  width: 100%;
  justify-content: space-between;
}

.modal-body .find-code-container svg{
  font-size: 13px;
}

.modal-body .find-code{
  background-image: url('/public/find-code.png');
  height: 257px;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 12px;
}

/* modali nere */

.black-modal .modal-content{
  background-color: #1c1c1c;
  border: 1px solid #B0B9CE;
}

.black-modal .modal-header .btn-close{
  background-image: url('/public/close-x-w.svg');
}

.black-modal .modal-header{
  border-bottom: 1px solid #E6E9EF;
}

.black-modal .modal-header h2 {
  color: var(--color-white);
}

.black-modal .modal-body{
  border-bottom: 1px solid #E6E9EF;
}

.black-modal .modal-body h4{
  color: var(--color-white);
}

.black-modal .modal-body input{
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #656565;
  color: #DBDBDB;
}

.black-modal .modal-body input::placeholder{
  color: #DBDBDB;
}

.black-modal .modal-body p{
  color: var(--color-white);
  font-weight: var(--font-weight-600);
  text-decoration: underline;
}

/* tabs */

.tabs-container .nav-item{
  width: 50%;
}

.tabs-container .nav-item .nav-link{
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  color: var(--color-medium-grey);
  width: 100%;
  border: none;
  background: #F8F9FB;
  border-radius: 4px 4px 0px 0px;
}

.tabs-container .nav-item .nav-link.active{
  color: var(--color-gradient-blue);
  background: #E6E9EF;
}

.tabs-container .tab-content{
  padding-top: 16px;
  padding-bottom: 8px;
}

.codeValid{
	border-color: var(--light-green) !important;
	color: var(--light-green) !important;
}
.codeInvalid{
	border-color: var(--magenta) !important;
	color: var(--magenta) !important;
}
