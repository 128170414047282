/* DASHBOARD */

.dash-container {
    padding: 32px 60px;
    max-width: 1440px;
    margin: auto;
}

.dash-container>.row {
    margin: 0 -12px;
}

.dash-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
    background: #1C1C1C;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    height: 100%;
    margin: 0 12px;
}

.dash-box.h-460 {
    max-height: 460px;
    min-height: 460px;
}

.dash-box.h-230 {
    height: 230px;
    justify-content: space-between;
}

.dash-box.selected-contents {
    height: 496px;
}

.dash-box.selected-contents .prod-cover {
    height: 400px;
}

.dash-box.selected-contents .prod-cover a{
    text-decoration: none;
    width: 100%;
}

.dash-box.selected-contents .prod-cover a:hover h6{
    text-decoration: underline;
}

.dash-box.dash-box.selected-contents .prod-cover p{
    padding: 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.internal-box {
    padding: 6px 8px;
    background: var(--color-black);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 94px;
    justify-content: space-between;
    margin: 0;
    gap: 0;
}

.dash-box h1 {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-24);
    line-height: var(--line-height-33);
    color: var(--color-lightgrey);
    padding: 8px;
}

.dash-box h2 {
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-28);
    line-height: var(--line-height-38);
    color: var(--color-lightgrey);
}

.dash-box h3 {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-lightgrey);
    text-align: left;
    text-transform: inherit;
    margin-bottom: 1em;
}

.dash-box h4 {
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    color: var(--color-lightgrey);
    margin-bottom: 0;
}

.dash-box h5 {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: var(--color-lightgrey);
}

.dash-box p {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-16);
    line-height: var(--line-height-22);
    color: #C4C4C4;
    margin: 0;
}

.dash-box img {
    max-width: 100%;
    border-radius: 4px;
}

.dash-box>img{
    min-height: 210px;
}

.dash-box.continue-box img{
    height: 364px;
    width: 273px;
    object-fit: cover;
}

.dash-box.continue-box .img-cover{
    position: relative;
}

.dash-box button.primary,
.dash-box a.primary {
    padding: 8px 16px;
    height: 40px;
    width: 263px;
    background: var(--color-gradient-purple);
    border-radius: 32px;
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
    letter-spacing: 0.4px;
    color: var(--color-white);
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
}

.dash-box button.primary:hover,
.dash-box a.primary:hover {
    background: #6222BD;
}

.dash-box .small-promo-container {
    height: calc(100% - 88px);
    overflow-y: auto;
    scrollbar-width: none;
}

.dash-box .small-promo-container h4{
    max-width: 85%;
}

.dash-box .gradient-button {
    width: 100%;
    margin: 12px 0 8px 0;
}

.dash-box .open-window {
    background-image: url('/public/icon-openwindow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 32px;
    width: 32px;
    border: none;
    background-color: #121212;
    border-radius: 50%;
}
.dash-box .open-window-smallbox {
    background-image: url('/public/icon-openwindow-smallbox.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 32px;
    width: 32px;
    border: none;
    background-color: #121212;
    border-radius: 50%;
}

/* personalizzazione stili carousel */

.custom-carousel-container {
    position: relative;
}

.custom-carousel-container .overlay {
    position: absolute;
    height: 260px;
    left: 0;
    right: 0;
    top: 0px;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.5) 60%, #121212 100%);
    mix-blend-mode: normal;
}

.custom-carousel-container li .prod-cover {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 12px;
    width: 100%;
    background: #252525;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    position: relative;
}

.dash-box.continue-box .img-cover .chip {
    left: 12px;
    margin: 0;
    width: 63px;
}

.dash-box.dash-box.continue-box .primary{
    width: 263px;
    display: block;
    text-align: center;
}

.custom-carousel-container .chip span {
    padding-left: 20px;
}

.big-carousel.custom-carousel-container .chip {
    background-image: url('/public/icon-new.svg');
    left: 8px;
}

.custom-carousel-container .chip.lock {
    background-image: url('/public/icon-lock.svg');
    left: initial;
    top: 228px;
    right: 0;
    background-position: center left 4px;
}

.custom-carousel-container .prod-cover img {
    height: 248px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
}

.custom-carousel-container h6,
.discover-box h6 {
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-20);
    line-height: var(--line-height-28);
    color: var(--color-lightgrey);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 12px 4px 0 4px;
    margin-bottom: 16px;
}

.custom-carousel-container.big-carousel h6{
    height: 4.6rem;
}

.custom-carousel-container .overlay h6,
.discover-box .overlay h6 {
    position: absolute;
    bottom: 0;
    background: rgba(18, 18, 18, 0.16);
    backdrop-filter: blur(4px);
    margin: 0;
    left: 8px;
    right: 8px;
    border-radius: 0 0 8px 8px;
    padding: 8px;
    padding-bottom: 5px;
    height: 4.4rem;
}

.custom-carousel-container .foryou-alt h6{
    -webkit-line-clamp: 3;
}

.custom-carousel-container .foryou-alt .prod-cover div{
    min-height: 114px;
}

.custom-carousel-container .purple-button, 
.custom-carousel-container .border-button{
    width: calc(221px - 16px);
}

.custom-carousel-container .purple-button{
    margin: 4px 0 12px 0;
}

.react-multi-carousel-list {
    height: 100%;
    width: 712px;
    margin: auto;
    align-items: baseline;
}

.big-carousel .react-multi-carousel-list {
    height: 100%;
    width: 1160px;
    margin: auto;
}

.react-multi-carousel-item>div {
    margin: 0 8px;
    min-height: 320px;
}

.custom-button-group {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-button-group img{
    height: 48px;
    width: 48px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 32px;
}

.discover-box .col{
    margin: 0 8px;
    position: relative;
    width: 263px;
}

.discover-box .gradient-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 424px;
    height: 42px;
    border-radius: 32px;
    margin: auto;
}

.discover-box .prod-cover{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    width: 268px;
    height: 300px;
    background: #252525;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: relative;
}
	@media (max-width: 1439px){
		.discover-box .prod-cover{
			/* max-width:170px; */
		}
	}

.discover-box .overlay{
   /* width: 252px;*/
    width: calc(100% - 16px);
    height: 284px;
    opacity: 0.5;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, rgba(18, 18, 18, 0.50) 60%, #121212 100%);
    position:absolute;
    bottom: 8px;
    border-radius: 4px;
}
.discover-box .chip{
    width: 71px;
    height: 24px;
    left: 12px;
    top: 12px;
    background-image: url("/public/discover.svg");
}

.discover-box img{
    max-height: 284px;
    margin: auto;
    display: block;
}

.discover-box .overlay h6{
    font-family: 'Source Serif 4';
    font-weight: var(--font-weight-700);
    font-size: 22px;
    line-height: var(--line-height-28);
    color: var(--color-white);
    padding: 6px 12px;
    width:100%;
    margin: 0;
    left: 0;
}

@media only screen and (max-width: 1460px) {
    .discover-box .col{
        width: 31%;
    }
    .discover-box .prod-cover {
        width: auto;
    }
}
