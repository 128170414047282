.checkout-container{
    width: 1440px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 0 60px;
}

.checkout-body{
    width: 896px;
}

.checkout-sidebar{
    width: 392px;
}

.checkout-container .account-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 72px;
    background: rgba(0, 30, 96, 0.08);
    flex: none;
    order: 0;
    align-self: stretch;
}

.checkout-container .account-top h1{
    font-weight: var(--font-weight-600);
    font-size: 22px;
    line-height: var(--line-heigth-30);
    color: var(--color-black);
    margin: 0 auto;
}

.checkout-body h2{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-24);
    line-height: var(--line-heigth-32);
    color: var(--color-black);
    margin: 16px 0;
}

.checkout-body .purchase-options h3{
    font-weight: var(--font-weight-700);
    font-size: 22px;
    line-height: var(--line-heigth-30);
    color: var(--color-gradient-blue);
    text-align: left;
    text-transform: none;
    margin: 0;
}

.checkout-container h4{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-18);
    line-height: var(--line-heigth-24);
    color: var(--color-black);
}

.checkout-body h5{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: var(--color-black);
}

.checkout-container .account-body{
    margin: 16px 0 64px 0;
}

.checkout-body form, 
.checkout-body .form-checkout{
    width: 648px;
    margin: auto;
    padding-bottom: 16px;
}

.checkout-body form input, 
.checkout-body .form-checkout input{
    max-width: 100%;
}

.checkout-body .purchase-options{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 648px;
    height: 104px;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 16px;
}

.checkout-body .purchase-options>div{
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    width: 312px;
    height: 104px;
    background: rgba(0, 30, 96, 0.08);
    border: 2px solid rgba(0, 30, 96, 0.12);
    border-radius: 8px;
}

.checkout-body .purchase-options p{
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: var(--color-dark-grey);
    margin: 0;
}

.checkout-body  input[type="radio"]{
    height: 20px;
    width: 20px;
    accent-color: var(--color-black);
}

.checkoption:has(input.selected) {
    background: #F8F9FB;
    border: 2px solid var(--color-gradient-purple);
    /*border-image-source: linear-gradient(98.36deg, #001E60 -0.32%, #6C25D0 100%);*/
}

.checkout-body .save-address{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    height: 40px;
    background: rgba(108, 37, 208, 0.12);
    border-radius: 4px;
    justify-content: space-between;
}

.checkout-body .save-address input{
    width: 20px;
    margin-right: 8px;
    accent-color: var(--color-gradient-purple);
}

.checkout-body .save-address p{
    color: var(--color-gradient-purple);
    margin: 0;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    justify-self: end;
}

.divider-row{
    height: 1px;
    border-bottom: 1px solid #9BABE1;
    padding-bottom: 8px;
}

.input-cvc{
    background-image: url("../../../public/creditcard.svg");
    background-position: center right 8px;
    background-repeat: no-repeat;
    background-size: 20px;
}

.checkout-body .text-block{
    padding: 8px 24px;
}

.checkout-body .text-block .col>div{
    margin-bottom: 16px;
}

.checkout-body .text-block p{
    margin: 0;
}

.checkout-body .small{
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-14);
    line-height: var(--line-heigth-20);
    margin: 0;
    color: var(--color-medium-grey);
}

.checkout-body .small span{
    text-decoration: none;
}

.checkout-body button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 163px;
    height: 36px;
    background: #F2F3F6;
    border: 2px solid #001E60;
    border-radius: 8px;
}

.checkout-body button span{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-20);
    color: var(--color-gradient-blue);
}

.checkout-body b{
    margin: 16px 0;
    display: block;
}

.checkout-body .customer-type{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 38px;
    background: #F8F9FB;
    border-radius: 4px 4px 0px 0px;
    margin: 1px;
    cursor: pointer;
}

.checkout-body .customer-type.active, 
.checkout-body .customer-type:hover{
    background: #E6E9EF;
}

.checkout-body .customer-type span{
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: var(--color-medium-grey);
}

.checkout-body .customer-type.active span, 
.checkout-body .customer-type:hover span{
    color: var(--color-gradient-blue);
}

.checkout-body .terms>div {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
}

.checkout-body .terms p{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-14);
    line-height: var(--line-heigth-20);
    color: var(--color-dark-grey);
    margin: 0;
}

.checkout-sidebar{
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    gap: 4px;
    background: #F2F3F6;
}

.checkout-sidebar h4{
    margin-bottom: 0;
}

.checkout-sidebar .checkout-item>.col-auto{
    min-width: 51px;
}

.checkout-sidebar .checkout-item{
    padding: 8px 0;
}

.checkout-sidebar .checkout-item p{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: var(--color-black);
    margin: 0;
}

.checkout-sidebar .checkout-item span{
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-14);
    line-height: var(--line-heigth-20);
    color: var(--color-medium-grey);
}

.checkout-sidebar input{
    height: 32px;
}

.checkout-sidebar input::placeholder{
    font-weight: var(--font-weight-500);
    font-size: 12px;
    line-height: var(--line-heigth-16);
    color: var(--color-dark-grey);
}

.checkout-sidebar .button.gradient-button{
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-14);
    letter-spacing: 0.2px; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 2px;
    width: 83px;
    height: 32px;
    border-radius: 4px;
}

.checkout-sidebar img{
    cursor: pointer;
}

.checkout-sidebar .toggle-arrow {
    transition: transform 0.3s ease;
}

.checkout-sidebar .toggle-arrow.rotate {
    transform: rotate(180deg);
}

.checkout-sidebar .discount-applied{
display: flex;
flex-direction: row;
align-items: center;
padding: 8px;
gap: 8px;
width: 392px;
height: 62px;
background: rgba(0, 30, 96, 0.08);
border: 2px solid rgba(0, 30, 96, 0.08);
border-radius: 4px;
}

.checkout-sidebar .discount-applied p{
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-16);
    line-height: var(--line-heigth-22);
    color: var(--color-gradient-blue);
}
