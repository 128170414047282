@media screen and (max-width:1440px){
    main .page.register,
    main .page.login, 
    main .page.password, 
    main .page.updated{
        width: 1440px;
    }
}

@media (min-width: 1025px) and (max-width: 1250px) {
    .header-row-bottom .search form{
        width: 510px;
    }
    .page-right{
        padding: 24px;
    }
 
    .dash-container .big-carousel .react-multi-carousel-list{
        width: 100%;
    }
 
    main .yellow-buttons-cont{
        flex-direction: row;
        display: flex;
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: none;
        padding: 0;
    }
 
    .dash-container .big-carousel .react-multi-carousel-list{
        width: 100%;
    }

    main .custom-carousel-container div{
        width: 100%;
    }

    main .react-multi-carousel-item{
        width: 228px !important;
        padding: 8px 8px 12px;
        gap: 12px;
    }

    main .dash-box>img{
        min-height: unset;
    }

    main button.yellow-button{
        min-width: 102px
    }
 
}


@media screen and (max-width:1024px){

    body{
        font-size: 0.875rem;
    }

    .header-row-bottom{
        width: 100vw;
    }

    header .header-wrapper .row{
        width: 100%;
        padding: 0 24px;
        max-width: 100vw;
    }

    header .header-wrapper .search form{
        width: 100%;
    }

    main .page,
    main .page.register,
    main .page.login, 
    main .page.password, 
    main .page.updated{
        width: 100%;
    }

    main .page-background{
        background-position: center;
    }

    main .page-right{
        padding: 24px;
    }
    main .page-right .google-access{
        width: auto;
    }
    main .page-right form{
        width: 100%;
        padding: 0;
    }

    main .page-right .custom-select-container{
        min-width: initial;
        width: 100%;
    }

    main .page-right .custom-select-container select{
        max-width: unset;
        width: 100%;
    }

    main .page.password form{
        width: 100%;
    }

    main .dash-container{
        padding: 24px;
    }

    main .dash-box.h-460 {
        max-height: none;
        min-height: auto;
    }

    main .discover-box .prod-cover{
        height: auto;
    }

    .dash-container .big-carousel .react-multi-carousel-list{
        width: 100%;
    }

    main .dash-box>img{
        min-height: auto;
        margin: auto;
    }

    main .activate-content{
       width: 100%;
       flex-direction: column;
       height: auto;
       padding: 32px;
    }

    main .activate-left{
        width: 100%;
    }

    main .activate-left img{
        width: 100%;
    }

    main .activate-content .activate-right{
        width: 100%;
        height: auto;
    }

    main .discover-box .row{
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        scrollbar-width: none;
    }

    main .discover-box .col{
        flex:0 0 263px;
        width: 263px;
        min-width: 263px;
    }

    main .custom-button-group{
        display: none;
    }

    main .react-multi-carousel-track {
        transform: translate3d(-245px, 0px, 0px) !important;
    }

    main .react-multi-carousel-item{
        width: 228px !important;
        padding: 8px 8px 12px;
        gap: 12px;
    }

    main .dash-box.selected-contents .prod-cover {
        margin: auto;
        width: 212px;
    }

    main .dash-box.dash-box-yourprod>.row{
        width: 100%;
        margin: auto;
    }
    main .dash-box.dash-box-yourprod>.row>div{
        padding: 0;
    }

    main .yellow-buttons-cont{
        flex-direction: row;
        display: flex;
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: none;
    }

    main .yellow-buttons-cont button.yellow-button{
        min-width: 92px;
    }

    main .custom-carousel-container .purple-button,
    main .custom-carousel-container .border-button,
    main .custom-carousel-container div{
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: none;
    }


    .App footer .footer-wrapper{
        width: 100%;
        padding: 24px;
    }

    main .account-container .account-body{
        padding: 16px;
        margin: 0;
        max-width: unset;
    }

    main .landing-edu .edu-cards{
        gap: 24px;
    }

    main .landing-edu .edu-cards .card{
        padding: 16px;
        min-height: 670px;
    }

    main .landing-edu .edu-cards .card,
    main .account-container .account-body .tabs-container, 
    main .account-container .account-body .product-container, 
    main .account-container .account-body .purchase-history, 
    main .account-container .account-body .history-item, 
    main .landing-edu .edu-cards, 
    main .card .bottom-info .action,
    main .card .content-top .top-info, 
    main .card .content-top .bottom-info, 
    main .card .bottom-info .promo, 
    main .card .bottom-info .pricing, 
    main .bottom-info .list, 
    main .card .bottom-info .pricing h3, 
    main .landing-edu .edu-faq, 
    main .landing-edu .edu-faq>div, 
    main .landing-edu .edu-faq h1{
        width: 100%;
        height: auto;
    }

    main .card .bottom-info .action a{
        width: auto;
    }

    main .account-container .account-top .row{
        width: 100%;
    }
    /* landing */

    main .landing-edu .edu-top, 
    main .landing-edu .top-headings, 
    main .landing-edu .top-headings h1{
        width: 100%;
        height: auto;
    }

    main .landing-edu .edu-faq, 
    main .landing-edu .edu-faq>div{
        flex-direction: column;
    }

    main .landing-edu{
        width: 100%;
        padding: 16px;
    }

    main .banner-content{
        padding: 0;
        width: 100%;
    }

    main .banner-landing{
        height: 278px;
        margin-bottom: 30vh;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    main .banner-headings{
        width: 75%;
        height: auto;
        padding: 16px;
    }

    main .banner-headings h1{
        font-size: 28px;
        line-height: var(--line-height-38);
        width: auto; 
        margin-bottom: 20px;
    }

    main .banner-headings h2{
        font-size: 18px;
        line-height: var(--line-height-24);
        width: auto;
        min-height: 70px;
    }

    main .banner-boxes{
        padding: 16px;
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        scrollbar-width: none;
    }

    main .content-landing{
        position: relative;
        z-index: 10;
        padding: 16px;
    }

    main .landing-cards{
        margin: 0;
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
    }
    main .banner-boxes>div{
        height: auto;
        flex: 0 0 318px;
        width: 318px;
        min-width: 318px;
    }
    main .banner-boxes>div button{
        height: auto;
    }
    main .content-landing, 
    main .activate-landing{
        width: 100%;
    }

    main .activate-landing{
        height: auto;
        padding: 0;
    }

    main .activate-right hr{
        width: 100%;
        margin-bottom: 24px;
    }

    main .collaborate-landing{
        height: auto;
        padding: 24px 0;
    }

    main .collaborate-cards.grid-2 .card-info, 
    main .collaborate-landing .card-content{
        width: 100%;
        height: auto;
    }

    main .collaborate-cards.grid-2 .card-info p{
        height: auto;
    }

    main .divider-landing{
        width: 100%;
        justify-content: center;
    }

    main .divider-landing hr{
        width: 66px;
    }

    main .landing-news{
        width: 100%;
        padding-bottom: 24px;
        flex-direction: row;
    }

    main .landing-news.grid-2>div, 
    main .landing-news.grid-3>div, 
    main .landing-news.grid-4>div{
        width: 48%;
        min-width: unset;
        max-width: unset;
        flex-basis: auto;
    }

    main .collaborate-cards{
        width: 100%;
    }

}

@media (min-width: 768px) and (max-width: 1023px) {

    main .landing-news{
        width: 100%;
        padding-bottom: 24px;
        flex-direction: column;
    }

    main .landing-news.grid-2>div, 
    main .landing-news.grid-3>div, 
    main .landing-news.grid-4>div{
        width: 100%;
        min-width: unset;
        max-width: unset;
    }

    main .content-landing .collaborate-cards{
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    main .content-landing .collaborate-cards{
        width: 100%;
        height: auto;
        flex-direction: column;
    }
 
    main .collaborate-cards, .collaborate-cards.grid-2 .card-info{
        height: auto;
    }
 
    main .collaborate-cards{
        flex-direction: column;
    }
    main .page-right .chips-container{
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
    }
}

@media screen and (max-width:767px){

    header{
        height: 72px !important;
    }

    header .header-wrapper .row{
        max-width: initial;
        padding: 0 16px;
        margin: 0;
    }

    header .header-row-bottom source{
        width: 40px;
    }

    header .faq{
        display: none;
    }

    header .header-row-top{
        display: none;
    }

    header .header-wrapper .search{
        display: none;
    }

    header .search-mobile{
        display: flex;
        align-items: center;
        justify-content: right;
    }

    header .search-mobile img{
        height: 24px;
    }

    header .search-mobile-form {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.5s ease-out, opacity 0.5s ease-out;
        opacity: 0;
    }

    header .open-menu-mobile{
        display: block;
        width: 77px;
        height: 32px;
        border: 1px solid #E6E9EF;
        border-radius: 4px;
        margin-left: 15px;
        margin-right: 5px;
        background-image: url('../public/hamburger.svg');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 36px;
        position: relative;
    }

    header .open-menu-mobile.open-menu-dash{
        background-image: url('../public/hamburger-w.svg');
        background-size: 24px;
        background-position: center left 4px;
    }

    header .menu-mobile{
        position: absolute;
        top: 78px;
        left: 0;
        right: 0;
        height: 100vh;
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.5s ease-out, opacity 0.5s ease-out;
        opacity: 0;
        padding: 32px;
    }

    header .menu-mobile ul{
        padding: 0;
        margin: 0;
        list-style: none;
        padding: 5px;
    }

    header .menu-mobile ul li a{
        text-decoration: none;
    }

    header .menu-mobile.show {
        max-width: 100%;
        background-color: var(--color-white);
        opacity: 1;
        display: block;
    }

    header .menu-mobile-dash.show{
        background-color: #121212;
    }

    header .menu-mobile-dash.show img{
        background: var(--color-lightgrey);
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 32px;
    }

    header .menu-mobile-dash.show h4{
        color: var(--color-lime);
        margin: 0;
    }
    
    header .menu-mobile li{
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
    }

    header .menu-mobile nav>ul>li>a{
        font-weight: var(--font-weight-600);
        font-size: 20px;
        line-height: var(--line-height-28);
    }

    header .menu-mobile nav>ul>li{
        margin-bottom: 24px;
    }

    header .menu-mobile-dash.show a{
        color: var(--color-white);
    }

    header .menu-mobile.show .menu-activate a{
        border-top: 1px solid #E6E9EF;
        border-bottom: 1px solid #E6E9EF;
        padding: 24px 0;
        background: linear-gradient(98.36deg, #001E60 -0.32%, #6C25D0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    header .menu-mobile-dash.show .menu-activate a{
        border-top: 1px solid var(--color-lightgrey);
        border-bottom: 1px solid var(--color-lightgrey);
        color: var(--color-lime);
        -webkit-text-fill-color: initial;
    }

    header .header-row-bottom button{
        margin-left: 10px;
    }
    
    header .search-mobile-form.show {
        max-width: 100%;
        opacity: 1;
        width: 80%;
    }

    header .search-mobile-form input{
        background-image: none;
    }

    main .page{
        width: 100%;
    }

    .page-background{
        display: none;
    }

    .page-right{
        padding: 24px;
        width: 100%;
    }

    .page-right form{
        max-width: 100%;
        padding-right: 0;
    }

    .page .divider{
        max-width: 100%;
    }

    .page-right .google-access{
        width: 100%;
    }

    main .page .container-check{
        padding: 0;
        min-width: 18px;
    }

    main .page .buttons-container{
        width: 94vw !important;
    }

    main .page .buttons-container a{
        margin-bottom: 16px;
    }

    .page-right label, 
    .page-right input{
        width: 100%;
        max-width: inherit;
    }

    .page-right form .row{
        margin: 0;
    }

    .page-right form .row>div{
        margin-bottom: 16px;
    }

    .page-right .login-error{
        gap: 8px;
        height: auto;
        display: flex;
        background: #F7E4E4;
        border-radius: 12px;
        padding: 8px;
        text-align: center;
        justify-content: center;
    }
    
    /* dashboard */
    main .dash-container{
        padding: 16px;
    }

    main .dash-container>.row{
        margin:0;
    }

    main .dash-box{
        margin: 0;
    }

    /*
    main .dash-box.dash-box-yourprod.h-460 {
	min-height:592px;
	}
    */

    main .dash-container .tag.bookmark{
        margin-right: 0;
    }

    main .dash-box.dash-box.continue-box .primary{
        width: auto;
        margin-top: 16px;
    }

    main .dash-box.continue-box img{
        width: 273px;
        height: auto;
        justify-self: center;
        display: flex;
    }

    main .dash-box>img{
        min-height: initial;
    }

    main .dash-box h2 {
        margin-top: 24px;
    }

    main .dash-box .yellow-button{
        min-width: 28%;
        margin: 8px;
    }

    main .dash-box .tags{
        justify-content: space-between;
    }

    main .dash-box .tag{
        margin: 0;
    }

    main .dash-box.continue-box{
        align-items: center;
    }

    /* footer */

    .App footer .footer-wrapper{
        padding: 16px;
    }

    .App footer .row:nth-of-type(1){
        padding: 0;
        row-gap: 24px;
    }

    .App footer .row{
        row-gap: 16px;
    }

    .App footer .social{
        margin: 24px 0;
    }

    .App footer .col-6.col-md-2{
        margin-bottom: 16px;
    }

    .App footer .col-md p{
        margin-bottom: 12px;
    }

    .App footer .terms{
        justify-content: start;
    }

    .App footer .terms a{
        margin-left: 0;
    }

    .App footer span{
        height: auto;
        padding-bottom: 24px;
    }

    .App footer h1{
        font-size: 22px;
    }

    /* profilo */

    main .account-container .account-top .row{
        width: 100%;
    }

    main .account-container .account-body h2{
        padding-top: 16px;
        margin-bottom: 0;
    }

    main .account-container .account-body .buttons-container input{
        margin-top: 16px;
    }

    .account-top .prev-page a{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px 8px 8px;
        gap: 6px;
        width: 40px !important;
        height: 40px;
        background: #FDFDFD;
        box-shadow: 0px 1px 3px 1px rgba(0, 30, 96, 0.15);
        border-radius: 24px;
        text-decoration: none;
    }
    
    .account-top .prev-page span{
        display: none;
    }

    /* i miei prodotti */

    main .account-container .account-body .product-container{
        padding: 8px;
    }

    main .account-container .account-body .product-container .gradient-button{
        max-width: 49%;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 4px;
    }

    main .account-container .account-body .product-container .d-flex{
        justify-content: space-between;
        gap: 4px;
    }

    main .account-container .accordion, 
    main .landing-edu .accordion{
        width: 100%;
    }

    /* slider */
    main .react-multi-carousel-list, 
    main .big-carousel .react-multi-carousel-list{
        width: 100%;
        margin: 0;
    }

    /* modali */

    .modal-dialog{
        width: 95vw;
    }

    /* landing */

    main .landing-edu{
        width: 100vw;
        padding: 16px;
        display:flex;
        flex-direction: column;
    }
    main .banner-landing{
        height: 656px;
        margin-bottom: 0;
    }
    main .collaborate-cards{
	height:392px;
    }

    main .landing-edu .edu-top, 
    main .landing-edu .top-headings, 
    main .landing-edu .top-headings h1, 
    main .landing-edu .top-headings h2{
        width: 100%;
        height:auto;
    }

    main .landing-edu .top-headings h1{
        font-size: 28px;
        line-height: 38px;
    }

    main .landing-edu .top-headings h2{
        font-size: 18px;
        line-height: 24px;
    }

    main .landing-edu .edu-cards{
        flex-direction: column;
        width: 100%;
        height: auto;
    }

    main .landing-edu .edu-cards .card{
        height: 680px;
        width: 100%;
        padding: 16px 24px;
    }

    main .landing-edu .edu-cards .card.disabled-card{
        height: 594px;
    }

    main .landing-edu .card-content,
    main .landing-edu .edu-cards .card a, 
    main .card .bottom-info .action, 
    main .card .content-top .top-info, 
    main .card .content-top .bottom-info, 
    main .card .bottom-info .list, 
    main .landing-edu .edu-info, 
    main .landing-edu .edu-faq, 
    main .landing-edu .edu-faq>div{
        width: 100%;
    }

    main .landing-edu .edu-faq>div{
        flex-direction: column;
    }

    main .card .bottom-info .pricing h3{
        width: 60%;
    }

    main .banner-headings{
        width: 90%;
        height: auto;
        padding: 24px 16px;
    }

    main .content-landing h1{
        font-weight: var(--font-weight-700);
        font-size: var(--font-size-28);
        line-height: var(--line-height-38);
    }

    main .activate-right button{
        height: auto;
        padding: 8px 16px;
        gap: 6px;
        width: 211px;
    }

}


